<template>
  <NuxtLayout name="base">
    <div class="base">
      <div class="container-dash">
        <div class="focus">
          <div class="centered">
            <slot />
          </div>
        </div>
      </div>
    </div>
  </NuxtLayout>
</template>

<style lang="scss" scoped>
@use './focusedWhite.scss';
</style>
